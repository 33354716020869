import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, of, shareReplay } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { CurrentUser, User } from '../models';
import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private currentUserSubject = new BehaviorSubject<CurrentUser | null>(null);
    public currentUser$ = this.currentUserSubject.pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    private readonly _logoutExecuting$ = new BehaviorSubject<boolean>(false);

    constructor(
        // private router: Router,
        private http: HttpClient,
        private settingsService: SettingsService
    ) {
        this.currentUserSubject.next(JSON.parse(localStorage.getItem('currentUser')))
    }

    /**
     * Gets the current user value.
     *
     * @return {User} The current user object.
     */
    public get currentUserValue(): User | null {
        return this.currentUserSubject.value?.user ?? null;
    }

    /**
     * Sets the current user value.
     *
     * @param {User} user - The new user object to be set as the current user.
     */
    public set currentUserValue(user: User) {
        let currentUser = this.currentUserSubject.value
        if (currentUser) currentUser.user = user

        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.currentUserSubject.next(currentUser);
    }

    /**
     * Gets the current user's access token.
     *
     * @return {string} The current user's access token.
     */
    public get currentUserToken(): string {
        return this.currentUserSubject.value?.access_token ?? ''; // JSON.parse(localStorage.getItem('currentUser'));
    }

    /**
     * Checks if the current user's email is verified.
     *
     * @return {boolean} True if the current user's email is verified, false otherwise.
     */
    public get currentUserEmailVerified(): boolean {
        return !!this.currentUserSubject.value?.user.email_verified_at;
    }

    /**
     * Sets the start location of the current user.
     *
     * @param {User} user - The user object containing the start location data.
     */
    public set currentUserStart(user: User) {
        let currentUser = this.currentUserSubject.value
        // currentUser.user = user
        if (currentUser) {
            currentUser.user.start_lon = user.start_lon
            currentUser.user.start_lat = user.start_lat
            currentUser.user.start_zoom = user.start_zoom
        }

        localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }

    // public demoLogin() {
    //     let customUser = Object.assign({}, new User());
    //     customUser.email_verified_at = "2023-06-28T13:26:10.000000Z";
    //     let user = {
    //         access_token: '...',
    //         message: '...',
    //         user: customUser,
    //     }
    //     localStorage.setItem('currentUser', JSON.stringify(user));
    //                 this.currentUserSubject.next(user);
    //                 this.router.navigate(['/']);
    // }

    /**
     * Logs in a user with the provided email and password.
     *
     * @param {string} email - The email of the user to log in.
     * @param {string} password - The password of the user to log in.
     * @return {Observable<CurrentUser>} The HTTP response from the login request.
     */
    login(email: string, password: string): Observable<CurrentUser> {
        return this.http
            .post<CurrentUser>(`${environment.apiUrl}/auth/login`, { email, password })
            .pipe(
                // tap(data => console.log('login',data)),
                tap((user) => {
                    // console.log('login',user)
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.settingsService.initSettings()
                    localStorage.setItem('settings', JSON.stringify({
                        backgroundMap: 'OSM',
                        layers: []
                    }));
                    this.currentUserSubject.next(user);
                    // return user;
                })
            );
    }

    /**
     * Verifies the email of the current user.
     *
     * @param {number} id - The ID of the user to verify the email for.
     * @param {string} hash - The verification hash for the user's email.
     * @return {Observable<CurrentUser>} The HTTP response from the email verification request.
     */
    verifyEmail(id: number, hash: string): Observable<CurrentUser> {
        return this.http.get<CurrentUser>(`${environment.apiUrl}/auth/email/verify/${id}/${hash}`)
            .pipe(
                tap((user) => {
                    // console.log('login',user)
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                })
            );
    }


    refresh() {
        return this.http
            .post<any>(
                `${environment.apiUrl}/auth/refresh`,
                {},
                {
                    headers: {
                        Authorization:
                            'Bearer ' + this.currentUserToken,
                    },
                }
            )
            .pipe(
                map((user) => {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                    return user;
                })
            );
    }

    me() {
        return this.http.post<User>(`${environment.apiUrl}/auth/me`, {});
    }

    /**
     * Logs out the user.
     *
     * @param {boolean} httpRequest - (optional) Whether to make an HTTP request to logout. Defaults to true.
     * @return {Observable<Object>} An observable that emits Object or null when the logout is complete.
     */
    logout(httpRequest: boolean = true): Observable<Object | null> {
        let logoutFunction = () => {
            localStorage.removeItem('currentUser');
            this.currentUserSubject.next(null);
            this._logoutExecuting$.next(false);
            setTimeout(() => location.reload(), 1000); //location.reload();
        }

        if (this._logoutExecuting$.value) {
            return of(null);
        }
        if (!this.currentUserSubject.value) {
            return of(null)
        }

        this._logoutExecuting$.next(true);

        // this.router.navigate(['/', 'login']);

        if (!httpRequest) {
            logoutFunction();
            return of(null);
        }

        return this.http.post(`${environment.apiUrl}/auth/logout`, {})
            // , {
            //     // headers: { "Authorization": "Bearer " + accessToken }
            // }
            .pipe(
                finalize(() => {
                    // this.router.navigate(['/', 'login'])
                    logoutFunction();
                    // localStorage.removeItem('currentUser');
                    // this.currentUserSubject.next(null);
                    // this._logoutExecuting$.next(false);
                    // location.reload();
                })
            );

    }

}


