import { EventEmitter, Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    shareReplay,
    startWith,
    switchMap, merge,
    tap,
    of} from 'rxjs';
import { AuthenticationService, LayerService, NodeService, PlotService } from './';
import { AppState, ItemSubLayer, LayerFeature, NodeRef, PlotRef, LayerCollection, GlobalLayer, NodeFeature, PlotFeature, Layer, IPlot } from '@app/shared/models';
import { filter, takeUntil, withLatestFrom } from "rxjs/operators";
import { EntityType, ISelectedEntity } from "@shared/models/entity-type";
import { ImusDestroyService } from './destroy.service';
import { SettingsService } from './settings.service';
import { node } from '../models/node-ref';


@Injectable({
    providedIn: 'root',
})
export class LayersStateService {
    private readonly _state = new AppState();
    private readonly _stateSubject: BehaviorSubject<AppState> = new BehaviorSubject<AppState>(this._state);
    
    public get state(): AppState {
        // return this._state;
        return this._stateSubject.value;
    }
    
    public readonly data$: Observable<AppState> = this._stateSubject;
    public readonly state$ = this.state.data$.pipe(shareReplay(1));

    public readonly layers$: Observable<LayerCollection> = this.layerService.changed$.pipe(
        startWith('start'),
        switchMap(() => this.layerService.getAll()),
        shareReplay(1),
    );
    // private readonly updateGlobal$ = new EventEmitter();
    public readonly globalLayers$ = this.layerService.allGlobalLayers$.pipe(
    // combineLatest([this.updateGlobal$, this.layerService.changed$]).pipe(
    // merge(this.updateGlobal$, this.layerService.changed$).pipe(
    // this.layerService.changed$.pipe(
        // startWith('start'),
        withLatestFrom(this.state$),
        switchMap(([layers, state]) => {
            let newLayers: GlobalLayer[] = []
            for (let layer of Array.from(state)) {
                if (layer[0].includes('GLOBAL'))
                    newLayers.push({
                        layer_id: Array.from(layer[1].children$.value)[0][0],  //Symbol(), 
                        layer_type_id: Array.from(layer[1].children$.value)[0][1].layerTypeId,
                        can_read: true, 
                        nodes: [],
                        plots: []
                    })
            }
            for (let layer of layers) {
                if (!newLayers.some(newLayer => newLayer.layer_type_id === layer.layer_type_id)) {
                    newLayers.push({
                        layer_id: layer.layer_id, //crypto.randomUUID(),  //Symbol(), 
                        layer_type_id: layer.layer_type_id,
                        // can_read: true, 
                        nodes: [],
                        plots: []
                    })
                }
                let newLayer = newLayers.find(newLayer => newLayer.layer_type_id === layer.layer_type_id)!
                newLayer.nodes = [...newLayer.nodes, ...layer.nodes ]
                newLayer.plots = [...newLayer.plots, ...layer.plots ]
            }
            return of(newLayers)
        }),
        withLatestFrom(this.plotService.plotTypes$),
        switchMap(([layers, plotTypes]) => {
            let layerFeatures: LayerFeature[] = []
            for (let layer of layers) {
                let layerFeature: LayerFeature = {
                    files: [],
                    rastr: [],
                    nodes: {
                        type: 'NodeCollection',
                        features: layer.nodes.map(node => {
                            return {
                                type: 'NodeFeature',
                                properties: {
                                    id: -node.id,//-node.node_id, //Symbol(),
                                    node_id: node.id, // node.node_id,
                                    layer_id: layer.layer_id,
                                    name: node.name,
                                    lat: node.lat,
                                    lon: node.lon,
                                    node_type: this.nodeService.nodeTypes.find(nodeType => nodeType.id === node.node_type_id),
                                    files: [],
                                    notes: null,
                                    on: true,
                                } as node.NodeResponse
                            } as NodeFeature
                        })
                    },
                    plots: { //PlotCollection,
                        type: 'PlotCollection',
                        plots: layer.plots.map(plot => {
                            return {
                                type: 'Plot',
                                properties: {
                                    id: -plot.plot_id, // Symbol(),
                                    layer: layer.layer_id,
                                    plot_id: plot.plot_id,
                                    name: plot.name,
                                    plot_type: plotTypes.find(plotType => plotType.id === plot.plot_type_id),
                                    start_node:
                                    {
                                        type: 'Node',
                                        properties: {
                                            node_id: plot.start_node,
                                        } as node.NodeResponse
                                    } as NodeFeature,
                                    end_node:
                                    {
                                        type: 'Node',
                                        properties: {
                                            node_id: plot.end_node,
                                        } as node.NodeResponse
                                    } as NodeFeature,
                                    length: null,
                                    section_length: null,
                                    diameter: null,
                                    slope: null,
                                    laying_year: null,
                                    //TODO plot.coords
                                    coords: plot.coords,
                                    notes: null,
                                    use: null,
                                    shape: null,
                                    material: null,
                                    coating: null,
                                    purpose: null,
                                    location: null,
                                    on: true,
                                    devices: { type: "DeviceCollection", devices: [] },
                                    sections: { type: "SectionCollection", Sections: [] },
                                    files: [],
                                } as IPlot
                            } as PlotFeature
                        }),
                    },
                    type: 'LayerFeature',
                    properties: {
                        ID: layer.layer_id,
                        user_id: null,
                        user_name: null,
                        access_level_type_id: undefined,
                        name: this.layerService.getNewLayerTypeById(layer.layer_type_id)?.russian || 'Новый слой',
                        color: '4f4f4f',
                        layer_type: this.layerService.getNewLayerTypeById(layer.layer_type_id),
                        order: 1,
                        data: null,
                        format: null,
                        w: null,
                        h: null,
                        files: [],
                        rastr: [],
                        scale: null,
                        rotate: null,
                        center: null,
                        objectLabels: false,
                    } as unknown as Layer,
                }
                layerFeature.plots?.plots.forEach(plot => {
                    plot.properties.start_node = layerFeature.nodes.features
                        .find(node => node.properties.node_id === plot.properties.start_node.properties.node_id)!
                    plot.properties.end_node = layerFeature.nodes.features
                        .find(node => node.properties.node_id === plot.properties.end_node.properties.node_id)!

                })
                layerFeatures.push(layerFeature)
            }
            return of(layerFeatures)
        }),
        // switchMap(LayerFeature => dfgdfg),
        shareReplay(1),
    );

    // Отдельный запрос по линиям
    // public readonly plots$: Observable<PlotCollection>  =  merge(
    //     this.layerService.updatedLayer$,
    //     this.layerService.changedLayer$,
    //     this.layerService.changed$
    // )
    // .pipe(
    //     startWith('start'),
    //     switchMap(() => this.plotService.getAll()),
    //     shareReplay(1),
    // );

    /** TODO: пока оставляю костыльное решение с хранением id */
    // private readonly _editableLayerId = new BehaviorSubject<number>(null);
    public readonly editableLayerId$: Observable<number | null> = this.state.editableLayerId$//this._editableLayerId;
    // public readonly editableLayerId$: Observable<number | null> = this.data$.pipe(
    //     switchMap(data => data.editableLayerId$)
    // ) 
    public set editableLayerId(layerId: number) {
        this.state.editableLayerId = layerId 
    }
    public get editableLayerId(): number | null {
        return this.state.editableLayerId
    }

    /** Эмитим после обновления слоя */
    public readonly layerUpdated$ = new EventEmitter<number>();
    public readonly settingsUpdated$ = new EventEmitter();

    constructor(
        private layerService: LayerService,
        private nodeService: NodeService,
        // private readonly mapService: MapService,
        private plotService: PlotService,
        private authenticationService: AuthenticationService,
        private settingsService: SettingsService,
        private readonly _destroy$: ImusDestroyService
    ) {
        this.authenticationService.currentUser$
            .pipe(
                tap((currentUser) => {
                    // console.log('authenticationService.currentUser$', currentUser)
                    if (currentUser) this.layerService.updateAllLayersInfo();
                    // Обнуление AppState при логоффе
                    else {
                        // console.log('state',this.state)
                        this.state.setToNull();
                        // this._state = new AppState();
                        // this.setData(null)
                    }
                }),
                takeUntil(this._destroy$)
            )
            .subscribe();
        this.layerService.allLayerTypes$.pipe(
            // tap(v => console.log('allLayerTypes$',v)),
            takeUntil(this._destroy$)
            )
            .subscribe(mapObj => this.state.allLayerTypes$.next(mapObj))
        this.layers$.pipe(takeUntil(this._destroy$)).subscribe()
        // this.globalLayers$.pipe(takeUntil(this._destroy$)).subscribe() // v => console.log('globalLayers$',v)
        // this.plots$.pipe(takeUntil(this._destroy$)).subscribe()
        
        this.data$
            .pipe(
                switchMap(appState => appState.items$),
                // tap(data => console.log('this.state.items$', data)),
                tap(items => this.settingsService.updateLayersSettings(items)),
                takeUntil(this._destroy$)
            )
            .subscribe();

        this.layerUpdated$
            .pipe(
                switchMap(() => this._stateSubject.value.items$),
                tap(items => this.settingsService.applyLayersSettings(items)),
                takeUntil(this._destroy$)
            )
            .subscribe()


        /** Обновление инфы по всем слоям */
        this.state.allLayerTypes$
            .pipe(
                filter(Boolean),
                switchMap((_) => this.layers$),
                tap((layerCollection) =>
                    layerCollection.features.sort(
                        (a, b) =>
                            a.properties.layer_type?.id -
                                b.properties.layer_type?.id ||
                            a.properties.order - b.properties.order
                    )
                ),
                tap((layerCollection) =>
                    layerCollection.features.map(
                        (feature, index) =>
                            (feature.properties.order = index + 1)
                    )
                ),
                // tap(layerCollection => console.log('layerCollection',layerCollection)),
                // New version start
                tap((layerCollection) =>
                    layerCollection.features.forEach((feature) =>
                        this._state.setLayer(feature)
                    )
                ),
                // debounceTime(300),
                // switchMap(_ => this.plotService.getAll()),
                // Отдельный запрос по линиям
                // switchMap(_ => this.plots$),
                // tap(plotCollection =>
                //     plotCollection.plots.map((plotFeature) => {
                //         this._state.setPlotToLayer(
                //             new PlotRef(plotFeature.properties)
                //         );   
                //     })
                // ),
                // Линии внутри запроса по слоям
                tap((layerCollection) =>
                    layerCollection.features.forEach((feature) =>
                        feature.plots?.plots.forEach((plotFeature) => {
                            this._state.setPlotToLayer(
                                new PlotRef(plotFeature.properties)
                            );
                        })
                    )
                ),
                // New version end
                
                // Добавляем глобальные слои
                switchMap((_) => this.globalLayers$),
                tap((layerFeatures) =>
                    layerFeatures.forEach((feature) =>
                        this._state.setLayer(feature)
                    )
                ),
                tap((layerFeatures) =>
                    layerFeatures.forEach((feature) =>
                        feature.plots?.plots.forEach((plotFeature) => {
                            this._state.setPlotToLayer(
                                new PlotRef(plotFeature.properties)
                            );
                        })
                    )
                ),
                //Применяем настройки
                switchMap((_) => this.state.items$),
                tap((items) => {
                    this.settingsService.applyLayersSettings(items);
                    this.setData();
                }),
                // tap(v => console.log('layers$',v)),
                takeUntil(this._destroy$)
            )
            .subscribe();

        let _id: number; // Временная переменная
        /** Обновление инфы по одному слою */
        merge(
            this.layerService.updatedLayer$.pipe(
                tap((layerFeature: LayerFeature) => _id = layerFeature.properties.ID)
            ),
            this.layerService.changedLayer$.pipe(
                tap(id => _id = id),
                switchMap(id => this.layerService.getById(id as number))
            )
        ).pipe(
            tap(layer => {
                this._state.setLayer(layer);
                const appState = this._state;
                appState.selectedEntity = appState.selectedPlot
                    ? { id: appState.selectedPlot, type: EntityType.PLOT }
                    : appState.selectedNode
                        ? { id: appState.selectedNode, type: EntityType.NODE }
                        : null;
            }),
            // Отдельный запрос по линиям
            // switchMap(_ => this.plots$),
            // tap(plotCollection => plotCollection.plots
            //     .filter(plot => plot.properties.layer == _id)
            //     .map(plotFeature => this._state.setPlotToLayer(new PlotRef(plotFeature.properties)))
            // ),
            // Линии внутри запроса по слоям
            tap(layerFeature => layerFeature.plots?.plots
                .forEach(plotFeature => this._state.setPlotToLayer(new PlotRef(plotFeature.properties)))
            ),
            tap(_ => this.layerUpdated$.emit(_id)),
            tap(_ => this.setData()),
            takeUntil(this._destroy$)
        )
            .subscribe();

        this.nodeService.changed$
            .pipe(
                tap(layerID => this.layerService.updateLayerInfo(layerID)),
                takeUntil(this._destroy$)
            )
            .subscribe();

        this.plotService.changed$
            .pipe(
                switchMap((id) => this.plotService.getById(id)),
                takeUntil(this._destroy$)
            )
            .subscribe((plot) => {
                // console.log(plot)
                this.state.setPlotToLayer(new PlotRef(plot.properties));
            });

        this.state.selectedEntity$.pipe(
            tap(value => {
                if (value || JSON.parse(localStorage.getItem('selectedEntity'))) {
                    // let valueNew = structuredClone(value) as ISelectedEntity & { node?: NodeRef; plot?: PlotRef }
                    let valueNew = Object.assign({}, value as ISelectedEntity & { node?: NodeRef; plot?: PlotRef })
                    if (valueNew?.type === 'node') valueNew.node = this.state.getNodeByNodeId(valueNew.id)
                    if (valueNew?.type === 'plot') valueNew.plot = this.state.getPlotByPlotId(valueNew.id)
                    localStorage.setItem('selectedEntity', JSON.stringify(Object.keys(valueNew).length !== 0 ? valueNew : null))
                }
            }),
            takeUntil(this._destroy$)
        )
        .subscribe()

        // this.state.data$.pipe(
        //     tap(data => console.log('data map',data)),
        //     takeUntil(this._destroy$)
        // )
        // .subscribe()

        // this.layerService.allGlobalLayers$.pipe(
        //     tap(data => console.log('allGlobalLayers$',data)),
        //     takeUntil(this._destroy$)
        // )
        // .subscribe()


    }

    // setData(newValue: Item[] = this.dataSubject.getValue()) {

    setData(newValue: AppState = this._stateSubject.getValue()) {
        this._stateSubject.next(newValue);
    }

    public getData(): AppState {
        return this._stateSubject.getValue();
    }

    // left-panel

    public hasLayerNode(layerId: number, nodeId: number): boolean {
        const item = <ItemSubLayer>(
            this._stateSubject.getValue().getLayerById(layerId)
        );
        return !!(
            item.nodes$ &&
            item.nodes$.getValue().find((node) => node.id == nodeId)
        );
    }

    public hasLayerPlot(layerId: number, plotId: number): boolean {
        const item = <ItemSubLayer>(
            this._stateSubject.getValue().getLayerById(layerId)
        );
        return !!(
            item.plots$ &&
            item.plots$.getValue().find((plot) => plot.id == plotId)
        );
    }


    /**
     * Удаляет Node из AppState
     * @param layerID
     * @param nodeId
     */
    public deleteNode(layerID: number, nodeId: number) {
        // debugger
        const appState: AppState = this._stateSubject.value;
        const nodes = (<ItemSubLayer>appState.getLayerById(layerID)).nodes$;
        const index = nodes.value.findIndex((node) => node.id === nodeId);
        if (index >= 0) {
            nodes.value.splice(index, 1);
            // if (appState.deleteNode(layerID, nodeId)) {
            appState.selectedNode = null;
            // const nodes = (<ItemSubLayer>appState.getLayerById(layerID)).nodes$;
            nodes.next(nodes.value);
            // this.setData()
        } else {
            throw new Error(`Node c id = ${nodeId} не существует`);
        }
    }

    /**
     * Удаляет Plot из AppState
     * @param layerID
     * @param plotId
     */
    public deletePlot(layerID: number, plotId: number) {
        // debugger
        const appState: AppState = this._stateSubject.value;
        const plots = (<ItemSubLayer>appState.getLayerById(layerID)).plots$;
        const index = plots.value.findIndex((plot) => plot.id === plotId);
        if (index >= 0) {
            plots.value.splice(index, 1);
            // if (appState.deletePlot(layerID, plotId)) {
            appState.selectedPlot = null;
            // const plots = (<ItemSubLayer>appState.getLayerById(layerID)).plots$;
            plots.next(plots.value);
            // this.setData()
        } else {
            throw new Error(`Plot c id = ${plotId} не существует`);
        }
    }

    public addNode(node: NodeRef) {
        // let item: Item;

        // item = this.getItem(node.layer);
        // if (!item.nodes) item.nodes = [];
        // item.nodes.push(node);
        // debugger
        const item = <ItemSubLayer>(
            this._stateSubject.value.getLayerById(node.layer!)
        );
        item.nodes$.value.push(node);
        /** Оповещаем подписчиков об изменении */
        item.nodes$.next(item.nodes$.value);
        // console.log('item.nodes$', item.nodes$)
    }

    public addPlot(plot: PlotRef) {
        const item = <ItemSubLayer>(
            this._stateSubject.value.getLayerById(plot.layer)
        );
        item.plots$.value.push(plot);
        /** Оповещаем подписчиков об изменении */
        item.plots$.next(item.plots$.value);
        // console.log('item.nodes$', item.nodes$)
    }

    /**
     * "Разворачивает" Plot - начальная Node становится конечной и наоборот, массив координат реверсируется
     * @param plot
     */
    public turnAroundPlot(plot: PlotRef) {
        // console.log('plot start', plot)
        let tempNode = plot.startNode;
        plot.startNode = plot.endNode;
        plot.endNode = tempNode;
        plot.coords.reverse();
        // console.log('plot end', plot)
    }
}
