import { Injectable } from '@angular/core';
import { NodeRef, PlotRef } from '@app/shared/models';
import { LayersStateService } from '@app/shared/services';
import { FeatureLike } from 'ol/Feature';
import { ColorLike } from 'ol/colorlike';
import { Coordinate } from 'ol/coordinate';
import { Geometry, GeometryCollection, LineString, MultiPolygon, Polygon } from 'ol/geom';
import { fromLonLat, transform } from 'ol/proj';
import { Fill, RegularShape, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { MapService } from './map.service';

import { CopyObjectsService } from "@services/copy-objects.service";
import { LayerAttributeTableService } from "@services/layer-attribute-table.service";
import { Color } from 'ol/color';
import { MapSearchService } from './mapSearch.service';
// import { Color } from 'ol/color';
// import { Color } from '@app/modules/statistics/models/color';

enum PlotTypeIds {
    // gravity_section = 1,
    // pressure_section = 2,
    // section = 3,
    // two_pipes = 4,
    GRAVITY_SECTION = 1,
    PRESSURE_SECTION = 2,
    SECTION = 3,
    TWO_PIPES = 4
}

@Injectable({
    providedIn: 'root'
})
export class MapDataService {
    private readonly redColor: ColorLike = '#cc2323'
    private readonly fillBlack = new Fill({ color: '#000000' })
    // private readonly fillGrey = new Fill({ color: Color.GREY })

    private readonly whiteStrokeWidth5 = new Stroke({ color: '#ffffff', width: 5 })
    private readonly blackStrokeWidth2 = new Stroke({ color: '#000000', width: 2 })
    private readonly blackCircleRadius4 = new CircleStyle({ radius: 4, fill: this.fillBlack })
    private readonly blackCircleRadius2 = new CircleStyle({ radius: 2, fill: this.fillBlack })


    // [
    //     {
    //       "id": 1,
    //       "name": "gravity section",
    //       "russian": "самотечный участок",
    //       "layer_type_id": 1,
    //       "icon": "icon.svg"
    //     },
    //     {
    //       "id": 2,
    //       "name": "pressure section",
    //       "russian": "напорный участок",
    //       "layer_type_id": 1,
    //       "icon": "icon.svg"
    //     },
    //     {
    //       "id": 3,
    //       "name": "section",
    //       "russian": "участок",
    //       "layer_type_id": 4,
    //       "icon": "icon.svg"
    //     },
    //     {
    //       "id": 4,
    //       "name": "two_pipes",
    //       "russian": "двухтрубный участок",
    //       "layer_type_id": 4,
    //       "icon": "icon.svg"
    //     }
    //   ]

    // public get PlotTypeIds(): Record<string, number> {
    //     let result: Record<string, number> = {}
    //     this.plotService.plotTypes$.pipe(shareReplay(1),takeUntil(this._destroy$)).subscribe(plotTypes => {
    //         plotTypes.forEach(plotType => {
    //             result[plotType.name] = plotType.id
    //         })
    //     })
    //     return result
    // }
    

    constructor(
        private mapService: MapService,
        private layersStateService: LayersStateService,
        private copyState: CopyObjectsService,
        private mapSearchService: MapSearchService,
        private attributeTableService: LayerAttributeTableService,
        // private plotService: PlotService,
        // private readonly _destroy$: ImusDestroyService
        // private layerEditService: LayerEditService,
    ) {
    }

    public getNodeColor(node: NodeRef, color: ColorLike): ColorLike {
        const multipleSelectedMode = this.copyState.isCopyLayerMode ? 'copy' : this.attributeTableService.isTableMode ? 'attribute' : null;
        let fillColor = (multipleSelectedMode
            ? this.isNodeByMultipleMode(node, multipleSelectedMode)
            : (node.id == this.layersStateService.getData().selectedNode))
            ? this.redColor
            : color
        if (this.mapSearchService.highlightedNode.value
            && this.mapSearchService.highlightedNode.value.properties
            && node.id == this.mapSearchService.highlightedNode.value.properties.id) {
            fillColor = this.redColor
        }
        return fillColor
    }

    public getNodeStyle(node: NodeRef, color: ColorLike): Style[] {
        // console.log('setPlotStyle')

        let styles: Style[] = [];
        let objectLabels = this.layersStateService.getData().getLayerById(node.layer)?.objectLabels ?? false
        // const viewRes = this.mapService.map.getView().getResolution() // 0.0001

        // const multipleSelectedMode = this.copyState.isCopyLayerMode ? 'copy' : this.attributeTableService.isTableMode ? 'attribute' : null;
        // let fillColor = (multipleSelectedMode
        //     ? this.isNodeByMultipleMode(node, multipleSelectedMode)
        //     : (node.id == this.layersStateService.getData().selectedNode))
        //     ? this.redColor
        //     : color

        // //TODO HIGHLIGHT

        // if (this.mapSearchService.highlightedNode.value
        //     && this.mapSearchService.highlightedNode.value.properties
        //     && node.id == this.mapSearchService.highlightedNode.value.properties.id) {
        //     fillColor = this.redColor
        // }
        const widthByZoom = this.mapService.getMap().getView().getZoom();
        const fillLayerColor = new Fill({ color: color })

        let text = objectLabels
            ? new Text({
                font: '14px Calibri,sans-serif',
                fill: this.fillBlack,
                stroke: this.whiteStrokeWidth5,
                textAlign: 'start',
                offsetY: -14,
                offsetX: -3,
                // backgroundFill: new Fill({
                //         color: 'rgba(255, 255, 255, 1)',
                //     }),
                text: node.name ?? undefined
            })
            : undefined

        // Колодец, точка, потребитель внешняя окружность
        let style1 = new Style({
            // fill: new Fill({
            //   color: color, //'rgba(255, 255, 255, 0.2)',
            // }),
            // stroke: new Stroke({
            //   color: '#000000', //'#33cc33',
            //   width: 2,
            // }),
            text: text,
            image: new CircleStyle({
                radius: 8,
                // radius: widthByZoom * 8 / 10,
                fill: fillLayerColor,
                stroke: this.blackStrokeWidth2, //'#33cc33'
            }),
        });

        // Разветвление
        let style11 = new Style({
            text: text,
            image: new CircleStyle({
                radius: 4,
                // radius: widthByZoom * 4 / 10,
                fill: fillLayerColor,
                stroke: this.blackStrokeWidth2,
            }),
        });

        // Резервуар, водопровод
        let style12 = new Style({
            text: text,
            image: new RegularShape({
                fill: fillLayerColor,
                stroke: this.blackStrokeWidth2,
                points: 4,
                radius: 10,
                // radius: widthByZoom * 10 / 10,
                angle: Math.PI / 4,
            }),
        });

        // Колодец - внутренний круг
        let style2 = new Style({
            image: this.blackCircleRadius4,
        });

        // Точка, потребитель - внутренний круг
        let style3 = new Style({
            image: this.blackCircleRadius2
        });

        switch (node.nodeType.id) {
            case 1: //Колодец, канализация
            case 2: //Точка, канализация
            case 6: //Колодец, водопровод
            case 4: //Потребитель, водопровод
            case 3: //Источник, водопровод
            case 9: //Ковер, водопровод
            case 10: //Камера, водопровод
            case 12: //Ковер, канализация
            case 13: //Камера, канализация
                styles.push(style1)
                switch (node.nodeType.id) {
                    case 1: //Колодец, канализация
                    case 6: //Колодец, водопровод
                    case 9: //Ковер, водопровод
                    case 10: //Камера, водопровод
                    case 12: //Ковер, канализация
                    case 13: //Камера, канализация
                        styles.push(style2)
                        break;
                    case 2: //Точка, канализация
                    case 4: //Потребитель, водопровод
                        styles.push(style3)
                        break;
                }
                break;
            case 5: //Резервуар, водопровод
                styles.push(style12)
                break;
            case 7: //Разветвление, водопровод
            case 8: //Разветвление, канализация
            case 11: //Заглушка, водопровод
            case 14: //Заглушка, канализация
                styles.push(style11)
                break;
        }

        // styles.push(style1)
        // if (node.nodeType.id == 1) styles.push(style2)
        // if (node.nodeType.id == 2) styles.push(style3)

        return styles;
    }

    public getPlotOffsetCoords(coords: Coordinate[], offset: number): Coordinate[] {
        let resultCoords: Coordinate[] = [];
        // let counter = 0;
        // console.log('coords', coords);

        offset = offset * (this.mapService.map.getView().getResolution() ?? 0)
        for (let i = 0; i < coords.length - 1; i++) {
            const from = (coords[i]);
            const to = (coords[i + 1]);
            const angle = Math.atan2(to[1] - from[1], to[0] - from[0]);
            const newFrom = [
                Math.sin(angle) * offset + from[0],
                -Math.cos(angle) * offset + from[1]
            ];
            resultCoords.push(newFrom);
            const newTo = [
                Math.sin(angle) * offset + to[0],
                -Math.cos(angle) * offset + to[1]
            ];
            resultCoords.push(newTo);
            // if (resultCoords.length > 2) {
            //     var intersection = Math.intersect(coords[counter], coords[counter + 1], coords[counter + 2], coords[counter + 3]);
            //     coords[counter + 1] = (intersection) ? intersection : coords[counter + 1];
            //     coords[counter + 2] = (intersection) ? intersection : coords[counter + 2];
            //     counter += 2;
            // }
        }

        // console.log('resultCoords', resultCoords);
        return resultCoords
    }
    public getPlotStyle(plot: PlotRef, color: ColorLike): Style[] {
        let styles: Style[] = [];
        // const viewRes = this.mapService.map.getView().getResolution() // 0.0001
        // let lineColor = this.getPlotColor(plot, color)
        // if (plot.id == this.layersStateService.getData().selectedPlot) {lineColor = this.redColor}

        // //TODO HIGHLIGHT
        // if (this.mapSearchService.highlightedPlot.value && this.mapSearchService.highlightedPlot.value.properties && plot.id == this.mapSearchService.highlightedPlot.value.properties.id) {
        //     lineColor = this.redColor
        // }

        let objectLabels = this.layersStateService.getData().getLayerById(plot.layer)?.objectLabels ?? false

        // let widthByZoom = this.mapService.getMap().getView().getZoom();

        // const stroke1 = this.whiteStrokeWidth5
        const stroke2 = new Stroke({
            color: color,
            // width: plot.plot_type?.id === this.PlotTypeIds['pressure section'] ? 5 : plot.plot_type?.id === this.PlotTypeIds['two_pipes'] ? 2 : 3,
            // width: plot.plot_type?.name === 'pressure section' ? 5 : plot.plot_type?.name === 'two_pipes' ? 2 : 3,
            width: plot.plot_type?.id === PlotTypeIds.PRESSURE_SECTION ? 5 : plot.plot_type?.id === PlotTypeIds.TWO_PIPES ? 2 : 3,
            // lineDash: plot.plot_type?.id === this.PlotTypeIds['pressure section'] ? [7, 12] : undefined
            lineDash: plot.plot_type?.id === PlotTypeIds.PRESSURE_SECTION ? [7, 12] : undefined
        });


        // const stroke4 = new Stroke({
        //     color: color,
        //     width: 3,
        //     lineDashOffset: 2
        // });

        // styles.push(new Style ({
        //     stroke: stroke1
        // }))
        styles.push(new Style({
            // stroke: plot.plot_type?.id !== this.PlotTypeIds['two_pipes'] ? stroke2 : new Stroke({ color: '#00000000', width: 0 }),
            // stroke: plot.plot_type?.name !== 'two_pipes' ? stroke2 : new Stroke({ color: '#00000000', width: 0 }),
            stroke: plot.plot_type?.id !== PlotTypeIds.TWO_PIPES ? stroke2 : new Stroke({ color: '#00000000', width: 0 }),
            text: objectLabels ? new Text({
                font: '14px Calibri,sans-serif',
                fill: this.fillBlack,
                stroke: this.whiteStrokeWidth5,
                // textAlign: 'start',
                // offsetY: 14,
                // offsetX: 5,
                // backgroundFill: new Fill({
                //         color: 'rgba(255, 255, 255, 1)',
                //     }),
                text: plot.name,
                placement: 'line',
                textBaseline: 'bottom',
                // overflow: true
            }) : undefined,
        }));

        // if (!plot.coords) {
        //     debugger
        // }
        // for (let i = 0 ; i < plot.coords.length-1; i++) {
        //     let start: Coordinate = plot.coords[i]
        //     let end: Coordinate = plot.coords[i+1]
        //     start = transform(start, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), ) //'EPSG:3857'
        //     end = transform(end,  'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(), )

        //     const dx = end[0] - start[0];
        //     const dy = end[1] - start[1];
        //     const delta: Coordinate = [(end[0] + start[0])/2, (end[1] + start[1])/2]
        //     const rotation = Math.atan2(dy, dx);
        //     const koeff = viewRes > 1000 ? 3000: viewRes*3

        //     const p1: Coordinate = [delta[0] - 2*koeff, delta[1] - koeff]
        //     const p2: Coordinate = [delta[0] - 2*koeff, delta[1] + koeff]

        //     let pol = new Polygon([[p1, delta, p2, p1]])
        //     pol.rotate(rotation, delta);
        //     styles.push(new Style({
        //         geometry: pol,
        //         stroke: stroke2,
        //         fill: new Fill({
        //             color: lineColor,
        //         })
        //     }))
        // }

        styles.push(new Style({
            // geometry: pol,
            geometry: //this.arrowGeometry,
                (feature) => {
                    // console.log('geometry function', this,feature)
                    // let geometry = feature.getGeometry();
                    // let geomArray: Geometry[] = [<Geometry>geometry];

                    // let pol = new Polygon([[p1, delta, p2, p1]])
                    // pol.rotate(rotation, delta);
                    // geomArray.push(pol)


                    // return  this.arrowGeometry(feature, transform(plot.coordinates[0], 'EPSG:4326', 'EPSG:3857' ), transform(plot.coordinates[plot.coordinates.length-1], 'EPSG:4326', 'EPSG:3857' )) //  this.getArrowGeometry(plot)//new GeometryCollection(geomArray);
                    return this.arrowGeometry(feature, fromLonLat(plot.startNode.coords), fromLonLat(plot.endNode.coords))


                },

            stroke: stroke2,
            fill: new Fill({
                color: color,
            })
        }))

        if (plot.plot_type?.id === PlotTypeIds.TWO_PIPES || plot.plot_type_id === PlotTypeIds.TWO_PIPES) {
            styles.push(new Style({
                // geometry: () => new LineString(this.getPlotOffsetCoords(plot.coordinates, -2)),
                geometry: (feature) => new LineString(this.getPlotOffsetCoords((feature.getGeometry() as LineString).getCoordinates(), -1.5)),
                stroke: stroke2,
            }))

            styles.push(new Style({
                geometry: (feature) => new LineString(this.getPlotOffsetCoords((feature.getGeometry() as LineString).getCoordinates(), 1.5)),
                stroke: stroke2,
            }))
        }

        return styles;

    }

    private isNodeByMultipleMode(node: NodeRef, mode: 'copy' | 'attribute'): boolean {
        switch (mode) {
            case "copy":
                return this.copyState.selectedNodes.includes(node.id as number)
            case "attribute":
                return this.attributeTableService.hasSelectedNode(node.id as number, node.layer_id);
            default:
                return false;
        }
    }

    private isPlotByMultipleMode(plot: PlotRef, mode: 'copy' | 'attribute'): boolean {
        switch (mode) {
            case "copy":
                return this.copyState.selectedPlots.includes(plot.id as number)
            case "attribute":
                return this.attributeTableService.hasSelectedPlot(plot.id as number, plot.layer);
            default:
                return false;
        }
    }

    arrowLines(coords: Coordinate[]): Coordinate[][][] {

        let resultCoords: Coordinate[][][] = [];
        const viewRes = (this.mapService.map.getView().getResolution() ?? 0) / 35 // 0.0001

        // let lineColor = '#' + color
        // if (plot.id == this.layersStateService.getData().selectedPlot) {lineColor = this.redColor}

        // const stroke1 = new Stroke({
        //     color: '#fffff',
        //     width: 5
        // })
        // const stroke2 = new Stroke({
        //     color: lineColor,
        //     width: 3
        // });

        // styles.push(new Style ({
        //     stroke: stroke1
        // }))
        // styles.push(new Style ({
        //     stroke: stroke2
        // }));

        for (let i = 0; i < coords.length - 1; i++) {
            let start: Coordinate = coords[i]
            let end: Coordinate = coords[i + 1]
            start = transform(start, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(),) //'EPSG:3857'
            end = transform(end, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(),)

            const dx = end[0] - start[0];
            const dy = end[1] - start[1];
            const delta: Coordinate = [(end[0] + start[0]) / 2, (end[1] + start[1]) / 2]
            const rotation = Math.atan2(dy, dx);

            const p1: Coordinate = [delta[0] - 200 * viewRes, delta[1] - 100 * viewRes]
            const p2: Coordinate = [delta[0] - 200 * viewRes, delta[1] + 100 * viewRes]

            let pol = new Polygon([[p1, delta, p2, p1]])
            pol.rotate(rotation, delta);
            resultCoords.push(pol.getCoordinates())
        }

        return resultCoords;

    }

    arrowStyleFunction = function (feature: FeatureLike, viewRes: number): Style | Array<Style> | void {
        let geometry = feature.getGeometry();
        let styles: Style[] = [];
        let color = feature.getProperties()['color']

        // console.log('arrowStyleFunction',viewRes,(<LineString>geometry).getCoordinates()[0])

        // let stroke1 = new Stroke({
        //     color: '#fffff',
        //     width: 5
        // })

        let stroke2 = new Stroke({
            color: color,
            width: 3
        });

        // styles.push(new Style ({
        //     stroke: stroke1
        // }))
        styles.push(new Style({
            stroke: stroke2
        }));


        let coords = (<LineString>geometry).getCoordinates()
        for (let i = 0; i < coords.length - 1; i++) {
            const start: Coordinate = coords[i]
            const end: Coordinate = coords[i + 1]
            const dx = end[0] - start[0];
            const dy = end[1] - start[1];
            const delta: Coordinate = [(end[0] + start[0]) / 2, (end[1] + start[1]) / 2]
            const rotation = Math.atan2(dy, dx);
            const koeff = viewRes > 1000 ? 3000 : viewRes * 3

            const p1: Coordinate = [delta[0] - 2 * koeff, delta[1] - koeff]
            const p2: Coordinate = [delta[0] - 2 * koeff, delta[1] + koeff]

            let pol = new Polygon([[p1, delta, p2, p1]])
            pol.rotate(rotation, delta);
            styles.push(new Style({
                geometry: pol,
                stroke: stroke2,
                fill: new Fill({
                    color: color
                })
            }))

        }

        // (<LineString>geometry).forEachSegment(function (start, end) {
        //     });

        return styles;
    }

    getArrowGeometry(plot: PlotRef): Geometry {

        // console.log('getArrowGeometry');

        let multyPolygon: MultiPolygon = new MultiPolygon([])
        // let geomArray: Geometry[] = []
        const viewRes = (this.mapService.map.getView().getResolution() ?? 0) / 35 // 0.0001

        for (let i = 0; i < plot.coordinates.length - 1; i++) {
            let start: Coordinate = plot.coordinates[i]
            let end: Coordinate = plot.coordinates[i + 1]
            start = transform(start, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(),) //'EPSG:3857'
            end = transform(end, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(),)

            const dx = end[0] - start[0];
            const dy = end[1] - start[1];
            const delta: Coordinate = [(end[0] + start[0]) / 2, (end[1] + start[1]) / 2]
            const rotation = Math.atan2(dy, dx);

            const p1: Coordinate = [delta[0] - 200 * viewRes, delta[1] - 100 * viewRes]
            const p2: Coordinate = [delta[0] - 200 * viewRes, delta[1] + 100 * viewRes]

            let pol = new Polygon([[p1, delta, p2, p1]])
            pol.rotate(rotation, delta)
            multyPolygon.appendPolygon(pol)
            // if (multyPolygon) multyPolygon.appendPolygon(pol)
            // else multyPolygon = new MultiPolygon([pol])
            // geomArray.push(pol)
        }

        return multyPolygon
    }

    getPlotStroke(plot: PlotRef, color: ColorLike): Stroke {
        // let lineColor = '#' + color
        if (plot.id == this.layersStateService.getData().selectedPlot) {
            color = this.redColor
        }
        return new Stroke({
            color: color,
            width: 5
        })
    }

    getPlotFill(plot: PlotRef, color: ColorLike): Fill {
        // let lineColor = '#' + color
        if (plot.id == this.layersStateService.getData().selectedPlot) {
            color = this.redColor
        }
        return new Fill({
            color: color
        })
    }

    getPlotColor(plot: PlotRef, color: ColorLike): ColorLike {
        let lineColor = color
        const multipleSelectedMode = this.copyState.isCopyLayerMode
            ? 'copy'
            : this.attributeTableService.isTableMode
                ? 'attribute'
                : null;
        if (multipleSelectedMode
            ? this.isPlotByMultipleMode(plot, multipleSelectedMode)
            : plot.id == this.layersStateService.getData().selectedPlot
        ) {
            lineColor = this.redColor
        }

        //TODO HIGHLIGHT
        if (this.mapSearchService.highlightedPlot.value && this.mapSearchService.highlightedPlot.value.properties && plot.id == this.mapSearchService.highlightedPlot.value.properties.id) {
            lineColor = this.redColor
        }

        return lineColor

    }

    /**
     * Рисует стрелки на линиях
     * @param feature
     * @returns
     */
    arrowGeometry(feature: FeatureLike, plotStartCoords: Coordinate, plotEndCoords: Coordinate): GeometryCollection {

        // console.log('arrowGeometry');

        let lineString = feature.getGeometry() as LineString;
        let geomArray: Geometry[] = [];// = [<Geometry>geometry];

        let widthByZoom = this.mapService.getMap().getView().getZoom();
        const viewRes = this.mapService.map.getView().getResolution() ?? 0
        // const viewRes = this.mapService.getMap().getView().getZoom();



        let coords = lineString.getCoordinates();
        if (
            coords[coords.length - 1][0] == plotStartCoords[0]
            && coords[coords.length - 1][1] == plotStartCoords[1]
            && coords[0][0] == plotEndCoords[0]
            && coords[0][1] == plotEndCoords[1]
        ) {
            // console.log('reverse')
            coords.reverse()
            lineString.setCoordinates(coords)
        }
        for (let i = 0; i < coords.length - 1; i++) {
            const start: Coordinate = coords[i]
            const end: Coordinate = coords[i + 1]
            const dx = end[0] - start[0];
            const dy = end[1] - start[1];
            const delta: Coordinate = [(end[0] + start[0]) / 2, (end[1] + start[1]) / 2]
            const rotation = Math.atan2(dy, dx);
            // const koeff = viewRes > 1000 ? 3000 : viewRes * 3
            const koeff = viewRes > 1000 ? 3000 : viewRes * 4
            // const koeff = viewRes > 1000 ? 3000 : viewRes
            // const koeff = viewRes * widthByZoom

            const p1: Coordinate = [delta[0] - 2 * koeff, delta[1] - koeff]
            const p2: Coordinate = [delta[0] - 2 * koeff, delta[1] + koeff]

            let pol = new Polygon([[p1, delta, p2, p1]])
            pol.rotate(rotation, delta);
            geomArray.push(pol)

        }

        // let styles = feature
        return new GeometryCollection(geomArray);
    }

    getPlotWithArrowsGeometry(plot: PlotRef): GeometryCollection {

        // console.log('getPlotWithArrowsGeometry', plot);

        let geomArray: Geometry[] = [];

        let multyPolygon: MultiPolygon = new MultiPolygon([])
        // let geomArray: Geometry[] = []
        const viewRes = (this.mapService.map.getView().getResolution() ?? 0) / 35 // 0.0001

        for (let i = 0; i < plot.coordinates.length - 1; i++) {
            let start: Coordinate = plot.coordinates[i]
            let end: Coordinate = plot.coordinates[i + 1]
            start = transform(start, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(),) //'EPSG:3857'
            end = transform(end, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(),)

            const dx = end[0] - start[0];
            const dy = end[1] - start[1];
            const delta: Coordinate = [(end[0] + start[0]) / 2, (end[1] + start[1]) / 2]
            const rotation = Math.atan2(dy, dx);

            const p1: Coordinate = [delta[0] - 200 * viewRes, delta[1] - 100 * viewRes]
            const p2: Coordinate = [delta[0] - 200 * viewRes, delta[1] + 100 * viewRes]

            let pol = new Polygon([[p1, delta, p2, p1]])
            pol.rotate(rotation, delta);
            multyPolygon.appendPolygon(pol)
            // if (multyPolygon) multyPolygon.appendPolygon(pol)
            // else multyPolygon = new MultiPolygon([pol])
            // geomArray.push(pol)
        }

        geomArray.push(new LineString(
            plot.coordinates.map(
                coords => transform(<Coordinate>coords, 'EPSG:4326', this.mapService.getMap().getView().getProjection().getCode(),)
            )));
        geomArray.push(multyPolygon);

        // (<LineString>geometry).forEachSegment(function (start, end) {
        //     let tempLine = new LineString([start, end]);
        //     let delta = tempLine.getCoordinateAt(0.5)
        //     let len = tempLine.getLength()
        //     let koeff = len /400
        //     var dx = end[0] - start[0];
        //     var dy = end[1] - start[1];
        //     // let delta: Coordinate = [(end[0] + start[0])/2, (end[1] + start[1])/2]
        //     var rotation = Math.atan2(dy, dx);

        //     // let len = Math.sqrt((dx.) ** 2 + )

        //     const p1 = [delta[0] - 20*koeff, delta[1] - 10*koeff]
        //     const p2 = [delta[0] - 20*koeff, delta[1] + 10*koeff]


        //     // var lineStr = new LineString([p1, end, p2]);
        //     // lineStr.rotate(rotation, end);
        //     // geomArray.push(lineStr)


        //     // var lineStr1 = new LineString([end, [end[0] - 200, end[1] + 200]]);
        //     // lineStr1.rotate(rotation, end);
        //     // var lineStr2 = new LineString([end, [end[0] - 200, end[1] - 200]]);
        //     // lineStr2.rotate(rotation, end);
        //     // geomArray.push(lineStr1)
        //     // geomArray.push(lineStr2)

        //     // let coords: Coordinate = [dx,dy]
        //     // let point = new Point(coords)
        //     // geomArray.push(point)

        //     let pol = new Polygon([[p1, delta, p2, p1]])
        //     pol.rotate(rotation, delta);
        //     geomArray.push(pol)

        // });
        return new GeometryCollection(geomArray);
    }

    useCoord(lat: string, long: string): boolean {
        const result =
            !Number.isNaN(Number(lat)) &&
            !Number.isNaN(Number(long)) &&
            (Number(lat) !== 0.0 || Number(long) !== 0.0);
        return result;
    }

    styleFunction = function (color: Color | ColorLike, objectLabels: boolean = false, name: string = ''): Style[] {
        // const styles = [];
        const width = 5;
        // console.log('styleFunction', color)
        // TODO константы общие для всего класса
        const fillLayerColor = new Fill({ color: color })
        const whiteStrokeWidth5 = new Stroke({ color: '#ffffff', width: 5 })
        const blackStrokeWidth2 = new Stroke({ color: '#000000', width: 2 })
        const fillBlack = new Fill({ color: '#000000' })

        return [
            new Style({
                image: new CircleStyle({
                    radius: 8,
                    fill: fillLayerColor,
                    stroke: blackStrokeWidth2, //'#33cc33'
                }),
            }),
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: width,
                }),
                text: objectLabels ? new Text({
                    font: '14px Calibri,sans-serif',
                    fill: fillBlack,
                    stroke: whiteStrokeWidth5,
                    text: name,
                    placement: 'line',
                    textBaseline: 'bottom',
                    // overflow: true
                }) : undefined,
            }),
        ];

    };

}

// export enum InteractionType {
//     select = 1,
//     /** Применяет Select ко всем слоям */
//     selectAll = 2,
//     draw = 3,
//     modify = 4,
//     snap = 5
// }
