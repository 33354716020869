import {
    Component,
    ElementRef,
    Inject,
    NgZone,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// import * as THREE from 'three';
import {
    AmbientLight,
    AxesHelper,
    Color,
    DirectionalLight,
    DoubleSide,
    GridHelper,
    Mesh,
    TorusKnotGeometry,
    MeshBasicMaterial,
    MeshPhongMaterial,
    MeshToonMaterial,
    PerspectiveCamera,
    Plane,
    PointLight,
    Scene,
    Vector3,
    WebGLRenderer,
    BackSide,
    FrontSide,
    Clock,
} from 'three';
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';
// import Stats from 'three/addons/libs/stats.module.js'

import { GUI } from 'dat.gui';
// import { GUI } from 'dat-gui';
// import {GUI} from "dat-gui";

import { ButtonComponentSettings } from '../button/button.component';
import { ThreeService } from '../../services/three.service';

@Component({
    selector: 'app-three-view',
    // standalone: true,
    // imports: [],
    templateUrl: './three-view.component.html',
    styleUrl: './three-view.component.scss',
})
export class ThreeViewComponent implements OnInit {
    buttonType = ButtonComponentSettings.ButtonType;

    modelFiles: any[] = [];
    // canvas = document.getElementById('canvas-box');

    @ViewChild('canvasContainer', { static: false })
    canvasContainer: ElementRef;

    @ViewChild('guiContainer', { static: false })
    guiContainer: ElementRef;

    // @ViewChild('canvas', { static: false })
    // canvas: HTMLCanvasElement;

    @ViewChild('canvas') canvasReference: ElementRef;
    get canvas(): HTMLCanvasElement {
        return this.canvasReference.nativeElement;
    }

    gui!: GUI;

    scene: any = new Scene();
    material: any = new MeshToonMaterial();
    materialCrop: any = new MeshPhongMaterial();
    geometry: any;
    camera: PerspectiveCamera;
    mesh: any;
    meshCrop: any;
    localPlane: any;
    guts: any;
    gutsMaterial: any;
    // renderer: THREE.WebGLRenderer;

    clipVector: Vector3 = new Vector3(1, 0, 0);
    clipPlanes: Plane[] = [new Plane(this.clipVector, 0)];

    clock: Clock = new Clock(true);
    // controlsConf: any; //TODO
    renderer: WebGLRenderer;
    // renderer = new WebGLRenderer({
    //     canvas: this.canvasReference.nativeElement,
    //     antialias: true,
    // });

    fpControls: FirstPersonControls;
    controls: OrbitControls;

    meshControls: any = new (function () {
        this.scaleX = 10;
        this.scaleY = 10;
        this.scaleZ = 10;

        this.positionX = 0;
        this.positionY = 4;
        this.positionZ = 0;

        this.rotationX = 0;
        this.rotationY = 0;
        this.rotationZ = 0;
        this.scale = 1;

        this.translateX = 0;
        this.translateY = 0;
        this.translateZ = 0;
    })();

    loader = new PLYLoader();

    public canvasSizes = {
        // width: window.innerWidth,
        // height: window.innerHeight,
        width: 680,
        height: 420,
        // width: 850,
        // height: 500,
    };

    objects: Mesh[] | any[] = [];

    constructor(
        public readonly dialogRef: MatDialogRef<ThreeViewComponent>,
        @Inject(MAT_DIALOG_DATA) private item: any,
        private threeService: ThreeService,
        private readonly zone: NgZone,
        private ngRenderer: Renderer2
    ) {}

    ngOnInit(): void {
        console.log('ThreeViewComponent ngOnInit item', this.item);
        this.gui = new GUI({ autoPlace: false });
    }

    ngAfterViewInit() {
        console.log('ThreeViewComponent ngAfterViewInit item', this.item);

        // this.onWindowResize();

        this.canvasSizes.width = window.innerWidth * 0.9;
        this.canvasSizes.height = window.innerHeight * 0.9;

        this.guiContainer.nativeElement.appendChild(this.gui.domElement);

        this.threeInit();

        this.guiInit();

        setTimeout(() => {
            this.loadModels();
        }, 100);

        setInterval(() => {
            this.render();
        }, 100);
    }

    closeModal() {
        this.dialogRef.close();
    }

    download() {
        console.log('this.camera', this.camera);
        console.log('this.mesh', this.mesh);
    }

    renderModels(geometry) {
        geometry.computeVertexNormals();
        this.material = new MeshPhongMaterial({
            // color: 0x44aa88,
            color: 0xffffff,
            // specular: 0x111111,
            shininess: 200,
            transparent: true,
            opacity: 0.8,
            // vertexColors: true,
        });
        // let material = new MeshBasicMaterial({
        //     color: 0xffffff,
        //     // specular: 0x111111,
        //     // shininess: 200,
        //     // vertexColors: true,
        // });
        this.mesh = new Mesh(geometry, this.material);

        // this.mesh.position.x = -0.2;
        this.mesh.position.x = 0;
        // mesh.position.y = -0.52;
        // this.mesh.position.y = -10;
        this.mesh.position.y = -50;
        this.mesh.position.z = 1.4;
        // mesh.scale.multiplyScalar( 0.0006 );
        this.mesh.scale.multiplyScalar(10);

        this.mesh.castShadow = true;
        this.mesh.receiveShadow = true;

        // // ***** Clipping planes: *****
        // var localPlane = new Plane(new Vector3(0, -1, 0), 0.8);

        // // Geometry
        // var cropMaterial = new MeshPhongMaterial({
        //     color: 0x80ee10,
        //     shininess: 100,
        //     side: DoubleSide,

        //     // ***** Clipping setup (material): *****
        //     clippingPlanes: [ localPlane ],
        //     clipShadows: true
        // });

        // var cropGeometry: any = new TorusKnotGeometry(0.4, 0.08, 95, 20);

        // var cropMesh = new Mesh(cropGeometry, cropMaterial);
        // cropMesh.castShadow = true;
        // this.scene.add(cropMesh);

        console.log('this.mesh', this.mesh);

        this.scene.add(this.mesh);
        this.objects.push(this.mesh);

        this.render();
    }

    loadModels() {
        this.modelFiles = this.threeService.searchModelInFiles(
            this.item.item.files
        );
        console.log('this.modelFiles', this.modelFiles);

        this.loader.load(this.modelFiles[0].src, (geometry) => {
            console.log('loader.load geometry', geometry);
            this.geometry = geometry;
            this.renderModels(geometry);

            // ***** Clipping planes: *****

            // this.clipVector = new Vector3( 1, 0, 0 ),
            (this.clipVector = new Vector3(1, 0, 0)),
                (this.clipPlanes = [new Plane(this.clipVector, 0)]);

            this.localPlane = new Plane(new Vector3(0, -1, 0), 0.8);
            // const globalPlane = new Plane( new Vector3( - 1, 0, 0 ), 0.1 );

            // Geometry

            this.materialCrop = new MeshPhongMaterial({
                color: 0x80ee10,
                shininess: 100,
                side: DoubleSide,

                // ***** Clipping setup (material): *****
                clippingPlanes: [this.localPlane],
                clipShadows: true,

                alphaToCoverage: true,
            });

            // const geometryCrop = new TorusKnotGeometry( 0.4, 0.08, 95, 20 );

            this.meshCrop = new Mesh(this.geometry, this.materialCrop);

            this.meshCrop.position.x = 0;
            // mesh.position.y = -0.52;
            // this.mesh.position.y = -10;
            this.meshCrop.position.y = -50;
            this.meshCrop.position.z = 1.4;
            // mesh.scale.multiplyScalar( 0.0006 );
            this.meshCrop.scale.multiplyScalar(10);

            this.meshCrop.traverse((child) => {
                child.castShadow = true;
                if (child.type == 'Mesh') {
                    child.material.clippingPlanes = this.clipPlanes;
                    child.material.clipShadows = true;
                    child.material.side = FrontSide;
                }
                //child.receiveShadow = true;
            });

            this.guts = this.meshCrop.clone(true);
            this.gutsMaterial = new MeshBasicMaterial({
                color: 'crimson',
                side: BackSide,
                clippingPlanes: this.clipPlanes,
                clipShadows: true,
            });

            this.guts.traverse((child) => {
                if (child.type == 'Mesh') {
                    child.material = this.gutsMaterial;
                }
            });

            this.meshCrop.castShadow = true;
            this.meshCrop.receiveShadow = true;

            // this.scene.add(this.meshCrop);
            this.scene.add(this.meshCrop, this.guts);

            // this.guiInit();
        });
    }

    guiInit() {
        // this.guiRotation.addEventListener('change', () => this.render());
        // if(this.meshControls)
        //     this.meshControls.addEventListener('change', () => this.render());

        this.controls.addEventListener('change', () => this.render());

        // let guiRRotation = this.gui.addFolder('RRotation');
        // guiRRotation.add(this.fpControls.position,'rotationX',-4,4);
        // guiRRotation.add(this.mesh.position,'rotationY',-4,4);
        // guiRRotation.add(this.mesh.position,'rotationZ',-4,4);
        // guiRRotation.open();

        const guiScale = this.gui.addFolder('scale');
        guiScale.add(this.meshControls, 'scaleX', 0, 50);
        guiScale.add(this.meshControls, 'scaleY', 0, 50);
        guiScale.add(this.meshControls, 'scaleZ', 0, 50);
        guiScale.open();

        // const controlss = {
        //     get near(){return  this.camera.near;},
        //     set near(value){
        //        this.camera.near = value;
        //        this.camera.updateProjectionMatrix();
        //        this.cameraHelper.update();
        //     },
        //     get far(){return  this.camera.far;},
        //     set far(value){
        //        this.camera.far = value;
        //        this.camera.updateProjectionMatrix();
        //        this.cameraHelper.update();
        //     },
        //     get aspect(){return  this.camera.aspect;},
        //     set aspect(value){
        //        this.camera.aspect = value;
        //        this.camera.updateProjectionMatrix();
        //        this.cameraHelper.update();
        //     },
        //     get fov(){return  this.camera.fov;},
        //     set fov(value){
        //        this.camera.fov = value;
        //        this.camera.updateProjectionMatrix();
        //        this.cameraHelper.update();
        //     },
        //     get positionX(){return  this.camera.position.x;},
        //     set positionX(value){
        //        this.camera.position.x = value;
        //        this.camera.updateMatrixWorld();
        //     },
        //     get positionY(){return  this.camera.position.y;},
        //     set positionY(value){
        //        this.camera.position.y = value;
        //        this.camera.updateMatrixWorld();
        //     },
        //     get positionZ(){return  this.camera.position.z;},
        //     set positionZ(value){
        //        this.camera.position.z = value;
        //        this.camera.updateMatrixWorld();
        //     }
        //   };

        const gui = new GUI();
        const perspectiveCameraFolder = gui.addFolder(' this.camera');
        //   perspectiveCameraFolder.add(controlss, 'near', 0, 50);
        //   perspectiveCameraFolder.add(controlss, 'far', 0, 200);
        //   perspectiveCameraFolder.add(controlss, 'aspect', 0, 4);
        //   perspectiveCameraFolder.add(controlss, 'fov', 0, 100);
        //   perspectiveCameraFolder.add(controls, 'positionX', -LIMIT, LIMIT);
        //   perspectiveCameraFolder.add(controls, 'positionY', -LIMIT, LIMIT);
        //   perspectiveCameraFolder.add(controls, 'positionZ', -LIMIT, LIMIT);

        let meshControlsCrop: any = {
            // enabled: 1,
            get Plane() {
                console.log('this.localPlane', this.localPlane);
                return this.localPlane ? this.localPlane.constant : {};
            },
            set Plane(v) {
                this.localPlane.constant = v;
            },
        };

        // let folderLocal = this.gui.addFolder('Local Clipping');
        // folderLocal.add(meshControlsCrop, 'Enabled', -4, 4);
        // folderLocal.add(propsLocal, 'Shadows');
        // folderLocal.add(meshControlsCrop, 'Plane', 0.3, 1.25);
        // folderLocal.open();

        let guiRotation = this.gui.addFolder('Rotation');
        guiRotation.add(this.meshControls, 'rotationX', -4, 4);
        guiRotation.add(this.meshControls, 'rotationY', -4, 4);
        guiRotation.add(this.meshControls, 'rotationZ', -4, 4);
        guiRotation.open();

        let guiPosition = this.gui.addFolder('Position');
        guiPosition.add(this.meshControls, 'positionX', -40, 40);
        guiPosition.add(this.meshControls, 'positionY', -40, 40);
        guiPosition.add(this.meshControls, 'positionZ', -40, 40);
        guiPosition.open();
        //   perspectiveCameraFolder.add(controls, 'positionX', -LIMIT, LIMIT);
        //   perspectiveCameraFolder.add(controls, 'positionY', -LIMIT, LIMIT);
        //   perspectiveCameraFolder.add(controls, 'positionZ', -LIMIT, LIMIT);

        const cameraFolder = this.gui.addFolder('Camera');
        cameraFolder.add(this.camera.position, 'z', 0, 20);
        cameraFolder.open();

        console.log('this.gui', this.gui);
    }

    threeInit() {
        this.scene.background = new Color(0x999999);

        this.scene.add(new AmbientLight(0x999999));

        const axesHelper = new AxesHelper(5);
        this.scene.add(axesHelper);

        const light = new DirectionalLight(0xffffff, 2);
        light.position.set(-1, -2.5, 1);
        this.scene.add(light);

        // const gridHelper = new GridHelper(12, 12);
        // const grid = new GridHelper(150, 150, 0xffffff, 0x555555);
        const grid = new GridHelper(120, 120);

        grid.rotateOnAxis(new Vector3(1, 0, 0), 90 * (Math.PI / 180));
        this.scene.add(grid);
        // this.scene.add(gridHelper);

        this.camera = new PerspectiveCamera(
            35,
            this.canvasSizes.width / this.canvasSizes.height,
            1,
            500
        );

        this.camera.up.set(0, 0, 1);
        // this.camera.position.set(0, -29, 6);
        this.camera.position.set(0.15, -66, 7);
        // this.camera.add(new PointLight(0xffffff, 250));
        this.scene.add(this.camera);

        // if (!this.canvas) {
        //     return;
        // }
        // this.canvas = document.getElementById('canvas') as HTMLCanvasElement;

        // var renderer = new THREE.WebGLRenderer( {antialias:true} );
        // renderer.setAnimationLoop( animate );
        // renderer.shadowMap.enabled = true;
        // renderer.localClippingEnabled = true;
        // document.body.appendChild( renderer.domElement );
        // document.body.style.margin = 0;
        // document.body.style.overflow = 'hidden';

        this.renderer = new WebGLRenderer({
            // let renderer = new WebGLRenderer({
            // canvas: this.canvasReference.nativeElement,
            antialias: true,
        });

        this.renderer.setAnimationLoop(this.animate.bind(this));

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(
            this.canvasSizes.width - 50,
            this.canvasSizes.height - 250
        );
        // this.renderer.localClippingEnabled = true;
        // this.renderer = this.canvasContainer.nativeElement.appendChild(
        //     renderer.domElement
        // );

        // ***** Clipping setup (renderer): *****
        // // const globalPlanes = [ globalPlane ],
        // 	let Empty = Object.freeze( [] );
        // this.renderer.clippingPlanes = Empty; // GUI sets it to globalPlanes
        // this.renderer.localClippingEnabled = true;

        this.ngRenderer.appendChild(
            this.canvasContainer.nativeElement,
            this.renderer.domElement
        );
        console.log('renderer?', this.renderer);

        // CONTROLS

        this.fpControls = new FirstPersonControls(
            this.camera,
            this.renderer.domElement
        );

        this.fpControls.lookSpeed = 0.0125;
        this.fpControls.movementSpeed = 500;
        this.fpControls.lookVertical = true;

        this.fpControls.lookAt(this.scene.position);

        //TWO
        this.controls = new OrbitControls(
            this.camera,
            this.renderer.domElement
        );

        this.controls.target.set(0, 0, 2);
        // this.controls.autoRotate = true;
        // this.controls.enableZoom = false;
        this.controls.update();

        this.camera.updateMatrixWorld();

        var vector: any = this.camera.position.clone();
        console.log('this.camera.matrixWorld', this.camera.matrixWorld);

        // vector.applyMatrix( this.camera.matrixWorld );
        -this.controls.addEventListener('change', () => this.render());
        // window.addEventListener('resize', () => this.onWindowResize());
        window.addEventListener('resize', () => this.onWindowResize());
    }

    onWindowResize() {
        console.log('...', this);

        // this.camera.aspect = window.innerWidth / window.innerHeight;

        // this.canvasSizes.width = this.canvasContainer.nativeElement.innerWidth;
        // this.canvasSizes.height = this.canvasContainer.nativeElement.innerHeight;
        this.canvasSizes.width = window.innerWidth * 0.9;
        this.canvasSizes.height = window.innerHeight * 0.9;

        console.log(
            'onWindowResize',
            window.innerWidth,
            window.innerHeight,
            this.canvasSizes.width,
            this.canvasSizes.height
        );

        // this.camera.aspect = this.canvasSizes.width / this.canvasSizes.height;
        // this.camera.updateProjectionMatrix();

        this.renderer.setSize(
            this.canvasSizes.width - 50,
            this.canvasSizes.height - 250
        );

        // let el = this.canvasReference.nativeElement;
        // el.setAttribute('style', 'color: white; background: red');
        // el.setAttribute('style', 'width: '+ this.canvasSizes.width +'px;');

        this.render();
    }

    createThreeJsBox(): void {
        // const canvas = document.getElementById('canvas-box');
        // this.scene = new THREE.Scene();
        // const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        // this.scene.add(ambientLight);
        // const pointLight = new THREE.PointLight(0xffffff, 0.5);
        // pointLight.position.x = 2;
        // pointLight.position.y = 2;
        // pointLight.position.z = 2;
        // this.scene.add(pointLight);
        // this.scene.add(torus, box);
        // this.camera = new THREE.PerspectiveCamera(
        //     75,
        //     this.canvasSizes.width / this.canvasSizes.height,
        //     0.001,
        //     1000
        // );
        // this.camera.position.z = 30;
        // this.scene.add(this.camera);
        // if (!canvas) {
        //     return;
        // }
        // this.renderer = new THREE.WebGLRenderer({
        //     canvas: this.canvas,
        // });
        // this.renderer.setClearColor(0xe232222, 1);
        // this.renderer.setSize(this.canvasSizes.width, this.canvasSizes.height);
    }

    render() {
        // console.log('render', this, this.renderer);

        if (this && this.mesh && this.meshControls) {
            this.mesh.rotation.x = this.meshControls.rotationX;
            this.mesh.rotation.y = this.meshControls.rotationY;
            this.mesh.rotation.z = this.meshControls.rotationZ;

            this.mesh.scale.x = this.meshControls.scaleX;
            this.mesh.scale.y = this.meshControls.scaleY;
            this.mesh.scale.z = this.meshControls.scaleZ;

            this.mesh.position.x = this.meshControls.positionX;
            this.mesh.position.y = this.meshControls.positionY;
            this.mesh.position.z = this.meshControls.positionZ;
        }

        // requestAnimationFrame(this.render);
        this.renderer.render(this.scene, this.camera);
    }

    // animation loop
    animate() {
        var time = this.clock.getElapsedTime();

        this.clipVector.set(Math.sin(2 * time), -1, Math.cos(time));
        this.scene.rotation.y = time / 10;

        this.renderer.render(this.scene, this.camera);
    }

    moveCameraPostion(posX: any, posY: any, posZ: any) {
        this.camera.position.set(posX, posY, posZ);
        this.render();
    }

    moveCameraDist(distance: number = 100) {
        this.camera.translateZ(distance);
        this.render();
    }

    mesgTransparentSet(value: any = 1) {
        console.log('meshTransparentStt', value);
    }

    meshTransparentToggle() {
        console.log('meshTransparentToggle', this.mesh, this.material);
        this.mesh.material.transparent = !this.mesh.material.transparent;
        this.render();
    }

    refreshData() {}
}
