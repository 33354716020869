import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CopyItemsResponse, GlobalLayer, GlobalPlot, GlobalResponce, Layer, LayerAccess, LayerCollection, LayerFeature, NewLayerResponse } from '@app/shared/models';
import { LAYER_TYPE_INIT_DATA, LayerTypeIDs, NewLayerType } from '@shared/models/layer-types';
import { Observable, map, merge, of, shareReplay, startWith, switchMap, tap } from "rxjs";
import { environment } from 'src/environments/environment';
import { node } from '../models/node-ref';
// import { UserService, AuthenticationService } from '@app/_services';

@Injectable({
    providedIn: 'root'
})
export class LayerService {

    public readonly changed$ = new EventEmitter<void>();
    public readonly changedLayer$ = new EventEmitter<number>();
    public readonly updatedLayer$ = new EventEmitter<LayerFeature>();
    public readonly updateGlobalLayer$ = new EventEmitter();
    public readonly allLayerTypes$: Observable<Map<number, NewLayerType>> = this.changed$.pipe(
        startWith(1),
        switchMap(() => this.getLayerTypes()),
        // map(collection => collection.layer_types.reduce((layerTypesMap: Map<number, NewLayerType>, layerType) => layerTypesMap.set(layerType.id, layerType), new Map())),
        switchMap(layerTypes => of([...layerTypes, ...LAYER_TYPE_INIT_DATA])),
        map(layerTypes => layerTypes.reduce((layerTypesMap: Map<number, NewLayerType>, layerType) => layerTypesMap.set(layerType.id, layerType), new Map())),
        tap(value => value.get(LayerTypeIDs.GEOJSON)!.hasColor = true),
        // tap(value => console.log('allLayerTypes', value)),
        tap(value => this._allLayerTypes = value),
        // tap(value => console.log('allLayerTypes$', value)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    public readonly mainLayerAccess$ = this.changed$.pipe(
        startWith(1),
        switchMap(() => this.getLayerAccess()),
        shareReplay(1)
    );
    public readonly canCreateNewLayer$ = this.mainLayerAccess$.pipe(
        map(rules => !!rules.can_create_new_layer)
    );
    // private readonly layerTypes = new Map ([
    //     [7, 12],
    //     [8, 13],
    //     [9, 14],
    //     [10, 15]
    //   ])
    private _globalLayerIds: number[] = []
    public readonly allGlobalLayers$ = merge(this.updateGlobalLayer$, this.changed$).pipe(
        startWith(1),
        switchMap(() => this.getAllGlobalLayers()),
        // tap(layers => layers.forEach(layer => layer.layer_type_id = this.layerTypes.get(layer.layer_type_id))),
        tap(layers => layers.forEach(layer => layer.layerType = this.getNewLayerTypeById(layer.layer_type_id))),
        tap(layers => this._globalLayerIds = layers.map(layer => layer.layer_id)),
        shareReplay(1)
    )
    public readonly globalLayerIds$ = this.allGlobalLayers$.pipe(
        map(layers => layers.map(layer => layer.layer_id)),
    )
    public get globalLayerIds() {
        return this._globalLayerIds
    }
    private _allLayerTypes: Map<number, NewLayerType> = new Map()

    constructor(
        private http: HttpClient,
    ) {
    }

    public updateLayerInfo(id: number) {
        this.changedLayer$.emit(id);
    }

    public updateAllLayersInfo() {
        this.changed$.emit();
    }

    /**
     * Возвращает объект NewLayerType по id типа слоя
     * @param id id типа слоя
     * @returns объект типа слоя
     */
    public getNewLayerTypeById(id: number): NewLayerType {
        return this._allLayerTypes.get(id)!
    }

    /**
     * Возвращает объект NewLayerType по полю name типа слоя и по наличию родителя
     * @param name поле name типа слоя
     * @param hasParent имеет родительский тип слоя, по умолчанию true
     * @returns объект типа слоя
     */
    public getNewLayerTypeByName(name: string, hasParent: boolean = true): NewLayerType {
        return Array.from(this._allLayerTypes)
            .find(value => value[1].name == name && !!value[1].parent_id == hasParent)![1]
    }

    /**
     * Получить все слои
     * @returns LayerCollection
     */
    getAll() {
        return this.http.get<LayerCollection>(`${environment.apiUrl}/layer`)
            .pipe(
                switchMap(collection => {
                    const newColl = new LayerCollection
                    newColl.data = collection.data
                    newColl.type = collection.type
                    newColl.features = collection.features.filter(feature => feature.properties.layer_type?.parent_id)
                    return of(newColl)
                })
            );
    }

    getAllGlobalLayers() {
        return this.http.get<GlobalResponce>(`${environment.apiUrl}/layer/global`)
            .pipe(
                switchMap(layers => {
                    let globalLayers: GlobalLayer[] = []
                    //TODO BAD Определить какие id относятся к глобальным
                    let keys = new Set([...Object.keys(layers.nodes), ...Object.keys(layers.plots)])
                    // console.log('layers.nodes.keys', keys)
                    let layerTypeIds = new Map(LAYER_TYPE_INIT_DATA.map(value => [value.parent_id, value.id]))
                    for (let i of keys) {
                        if (layers.nodes[+i] || layers.plots[+i]) {
                            globalLayers.push({
                                layer_id: +i,
                                layer_type_id: layerTypeIds.get(+i)!,// +i + 100,
                                nodes: (layers.nodes[+i] ?? []) as node.NodeGlobalResponse[],
                                plots: (layers.plots[+i] ?? []) as GlobalPlot[],
                            })
                        }
                    }

                    // console.log('globalLayers', globalLayers)
                    return of(globalLayers)
                }),
                // tap(v => console.log('getAllGlobalLayers',v))
            );
    }

    getById(id: number) {
        return this.http.get<LayerFeature>(`${environment.apiUrl}/layer/${id}`);
    }

    getLayerAccess(id?: number) {
        return this.http.get<LayerAccess>(`${environment.apiUrl}/layer/access${id ? '/' + id : ''}`);
    }

    // add(name: string, layerTypeId: number): Observable<NewLayerResponse> {
    //     return this.http.post<NewLayerResponse>(`${environment.apiUrl}/layer`, {
    //             name: name,
    //             layer_type_id: layerTypeId
    //         }
    //     );
    // }

    add(layer: Layer): Observable<NewLayerResponse> {
        return this.http.post<NewLayerResponse>(`${environment.apiUrl}/layer`, {
            name: layer.name,
            layer_type_id: layer.layer_type.id,
            order: layer.order,
            geojson: layer.geojson ?? undefined,
            format: layer.format ?? undefined
        }
        );
    }

    addRastr(name: string, file?: File): Observable<NewLayerResponse> {
        // console.log('addRastr', this._allLayerTypes)
        const data = new FormData();
        data.append('name', name);
        data.append('layer_type_id', LayerTypeIDs.RASTR_CHILD.toString());
        // this.getNewLayerTypeByName(LayerTypes.rastr).id.toString()
        // Array.from(this._allLayerTypes)
        //     .find(value => value[1].name == 'RASTR' && value[1].parent_id != null)[1].id
        //     .toString()); //LayerTypeIDs[LayerTypes.rastr].toString()); // '6'
        if (file) data.append('rastr', file, file.name);
        data.append('order', (Math.floor(Math.random() * 99) + 1).toString());
        return this.http.post<NewLayerResponse>(`${environment.apiUrl}/layer`, data);
    }

    addFile(layerId: number, data: FormData): any {
        return this.http.post(`${environment.apiUrl}/layer/${layerId}/file`, data);
    }

    // addRastrFile(layerId: number, file: File): any {
    //     const data = new FormData();
    //     data.append('file', file, file.name);
    //     return this.http.post(`${environment.apiUrl}/layer/${layerId}/file`, data);
    // }

    edit(layer: Layer, file?: File, rastr?: File) {
        const data = new FormData();
        // let layerID: number

        // if (layer instanceof Layer) {
        // layerID = layer.ID
        data.append('name', layer.name);
        data.append('layer_type_id', layer.layer_type.id.toString());
        data.append('order', layer.order.toString());
        data.append('color', layer.color);

        // data.append('data', layer.data.map(item => item.toString()));
        data.append('data', JSON.stringify(layer.data));
        data.append('w', layer.w?.toString());
        data.append('h', layer.h?.toString());
        data.append('format', layer.format);
        // }
        if (file) data.append('file', file, file.name);
        if (rastr) data.append('rastr', rastr, rastr.name);

        return this.http.put(`${environment.apiUrl}/layer/${layer.ID as number}`, data);
    }

    editPutLayer(layer: Layer) {
        const data = {
            name: layer.name,
            layer_type_id: layer.layer_type.id,
            order: layer.order ?? undefined,
            color: layer.color ? layer.color.replace('#', '') : undefined,
            data: layer.data ?? undefined,
            w: layer.w ?? undefined,
            h: layer.h ?? undefined,
            format: layer.format ?? undefined,
            is_global: layer.global ?? undefined,
            // files: AttachmentModel[];
            // rastr: AttachmentModel[];
            scale: layer.format ?? undefined,
            rotate: layer.format ?? undefined,
            center: layer.format ?? undefined,
            access_level_type_id: layer.access_level_type_id ?? undefined,
        }

        return this.http.put(`${environment.apiUrl}/layer/${layer.ID as number}`, data);
    }

    //layer: NewLayerResponse
    editPut(layer: any) {
        const data = {
            name: layer.name,
            // layer_type_id: layer.layer_type_id
            //                 ? parseInt(layer.layer_type_id)
            //                 : layer.layerTypeId ? parseInt(layer.layerTypeId) : parseInt(layer.layer_type.id),
            layer_type_id: layer.layer_type_id || !layer.layerTypeId
                ? parseInt(layer.layer_type_id)
                : parseInt(layer.layerTypeId),
            order: layer.order ?? undefined, // Math.floor(Math.random() * 99) + 1,
            color: layer.color ? layer.color.replace('#', '') : undefined,
            data: layer.data ?? undefined,
            w: layer.w ?? undefined,
            h: layer.h ?? undefined,
            format: layer.format ?? undefined,
            scale: layer.scale ?? undefined,
            rotate: layer.rotate ?? undefined,
            center: layer.center ?? undefined,
            is_global: layer.global ?? undefined
        }

        // const data = new FormData();
        // data.append('name', layer.name);
        // data.append('layer_type_id', layer.layer_type_id
        //                                 ? layer.layer_type_id.toString()
        //                                 : layer.layerTypeId ? layer.layerTypeId.toString() : layer.layer_type.id.toString());
        // data.append('order', layer.order ? layer.order.toString() : Math.floor(Math.random() * 99) + 1);
        // data.append('color', layer.color);
        // data.append('data', JSON.stringify(layer.data));
        // data.append('w', layer.w?.toString());
        // data.append('h', layer.h?.toString());
        // data.append('format', layer.format);

        return this.http.put(`${environment.apiUrl}/layer/${layer.layer_id}`, data);

    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/layer/${id}`);
    }

    // getLayerTypes(): Observable<LayerTypesCollection> {
    //     return this.http.get<LayerTypesCollection>(`${environment.apiUrl}/layer_type`);
    // }
    getLayerTypes(): Observable<NewLayerType[]> {
        return this.http.get<NewLayerType[]>(`${environment.apiUrl}/layer_type`);
    }

    joinLayers(data: { acceptor_id: number; donor_id: number }): Observable<LayerFeature> {
        return this.http.post<LayerFeature>(`${environment.apiUrl}/layer/join_layers`, data).pipe(
            tap(layer => this.updatedLayer$.next(layer))
        );
    }

    copyItems(req: CopyItemsResponse): Observable<{ layer: LayerFeature }> {
        return this.http.post<{ layer: LayerFeature }>(`${environment.apiUrl}/layer/copy_items`, req).pipe(
            tap(res => this.updatedLayer$.next(res.layer))
        );
    }
}
